import React, { useEffect, useState } from "react";
import { Container, DropdownButton, Image, Nav, Navbar } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <div className="top-header">
        <Marquee gradient={false} speed={50} className="text-white">
          Providing personalized loan services to meet your financial needs!
        </Marquee>
      </div>
      <Navbar
        className={`header ${isSticky ? "sticky" : ""}`}
        variant="dark"
        expand="lg"
      >
        <Container className="">
          <Navbar.Brand href="#home" className="py-0">
            <Image
              src="/logo-theme.png"
              alt="logo-theme"
              className="logo-theme"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div className="d-grid flex-column w-90">
            <div className="d-none d-md-flex align-items-center justify-content-around flex-wrap contact-header">
              <div className="d-flex gap-3 align-items-center ">
                <div className="header-box">
                  <i className="bx bx-stopwatch fs-4 text-white"></i>
                </div>
                <div>
                  <h6 className="mb-1 fw-bold">Opening hours</h6>
                  <p className="text-gray txt-14 mb-0">
                    Mon - Fri : 9 AM - 8 PM
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div className="header-box">
                  <i className="bx bx-phone fs-4 text-white"></i>
                </div>
                <div>
                  <h6 className="mb-1 fw-bold">Call Us</h6>
                  <p className="text-gray txt-14 mb-0">+91 - 8899889900</p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div className="header-box">
                  <i className="bx bx-envelope fs-4 text-white"></i>
                </div>
                <div>
                  <h6 className="mb-1 fw-bold">Mail us</h6>
                  <p className="text-gray txt-14 mb-0">chhatrapati@gmail.com</p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div className="header-box">
                  <i className="bx bx-cog fs-4 text-white"></i>
                </div>
                <div>
                  <h6 className="mb-1 fw-bold">Language</h6>
                </div>
              </div>
            </div>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="top-header menu-header"
            >
              <Nav className="mx-auto">
                <Nav.Link href="#home" className="">
                  Home
                </Nav.Link>
                <Nav.Link href="#about" className="dropdown-link">
                  About us
                  <ul class="sub-menu">
                    <li class="">
                      <a href="#" className="text-black" aria-current="page">
                        Brief about Patsanstha
                      </a>
                    </li>
                    <li class="">
                      <a href="" className="text-black">
                        Vision, Mission
                      </a>
                    </li>
                    <li class="">
                      <a href="" className="text-black">
                        History
                      </a>
                    </li>
                  </ul>
                </Nav.Link>
                <Nav.Link href="#interest" className="">
                  Interest Rates
                </Nav.Link>
                <Nav.Link href="#blogs" className="">
                  Why?
                </Nav.Link>
                <Nav.Link href="#Nnews" className="">
                  News and Events
                </Nav.Link>
                <Nav.Link href="#gallery" className="">
                  Gallery
                </Nav.Link>
                <Nav.Link href="#schemes" className="">
                  Schemes
                </Nav.Link>
                <Nav.Link href="#contact" className="">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </section>
  );
};

export default Header;
