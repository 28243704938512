import React, { useState } from "react";
import { Image, Tab, Tabs } from "react-bootstrap";
import { motion } from "framer-motion";
import { Tilt } from "react-tilt";

const Services = () => {
  const [selectedTab, setSelectedTab] = useState("Deposit");

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };
  const bgImageNews = {
    backgroundImage: "url('news-enent.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <section
        className="services-content p-32px mt-4"
        data-aos="fade-up"
        style={bgImageNews}
      >
        <div className="container">
          <div className="text-center mb-3 mb-lg-5" data-aos="fade-right">
            <h6 className="txt-theme">Schemes</h6>
            <h2 className="fw-bold txt-gray-dark">Our Latest Schemes</h2>
          </div>
          <Tabs
            activeKey={selectedTab}
            onSelect={(key) => setSelectedTab(key)}
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="Deposit" title="Fixed Deposit Scheme">
              {selectedTab === "Deposit" && (
                <motion.div
                  className="row"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{ duration: 0.7 }}
                >
                  <div className="col-md-6 col-lg-7 mb-3 mb-lg-0">
                    <p className="opacity-75">
                      Whether it's a business or a job, money often comes in and
                      slips away like sand through your fingers without you even
                      realizing it. As a result, the savings you manage to
                      accumulate also tend to disappear. Hence, it is important
                      to invest this money in a secure place. For this very
                      reason, Shivpratap Multistate Society's Fixed Deposit
                      Scheme is at your service.
                    </p>
                    <ul className="list-unstyled mb-3 mb-lg-4 services-list">
                      <li>Guaranteed returns at the end of the tenure.</li>
                      <li>Helps inculcate disciplined saving habits.</li>
                      <li>Option to renew or withdraw upon maturity.</li>
                      <li>
                        Interest payouts can be periodic or at maturity, as per
                        choice.
                      </li>
                    </ul>
                    <button className="download-btn bg-secondary-theme">
                      <span>View Details</span>
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <Tilt
                      options={{
                        max: 10, // max tilt rotation (in degrees)
                        scale: 1.01, // scale when tilted
                        speed: 900, // speed of tilt animation
                        glare: true, // enable glare effect
                        "max-glare": 0.5,
                      }}
                    >
                      <div className="text-center services-image">
                        <Image
                          src="fixed-deposite.png"
                          alt="fixed-deposite"
                          className="w-100"
                        />
                      </div>
                    </Tilt>
                  </div>
                </motion.div>
              )}
            </Tab>
            <Tab eventKey="Pension" title="Pension Deposit Scheme">
              {selectedTab === "Pension" && (
                <motion.div
                  className="row"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{ duration: 0.5 }}
                >
                  <div className="col-md-6 col-lg-7">
                    <p className="opacity-75">
                      The Pension Deposit Scheme (PDS) is typically a financial
                      program offered by banks or financial institutions aimed
                      at senior citizens or retirees. It provides a secure way
                      to deposit lump-sum savings and earn regular interest
                      income to supplement retirement funds. Here are the key
                      details:
                    </p>
                    <ul className="list-unstyled mb-3 mb-lg-4 services-list">
                      <li>Assured fixed income at regular intervals.</li>
                      <li>
                        Helps maintain financial independence during retirement.
                      </li>
                      <li>Safe and reliable investment option.</li>
                      <li>
                        Interest payouts customized (monthly/quarterly/annually)
                        as per choice.
                      </li>
                    </ul>
                    <button className="download-btn bg-secondary-theme">
                      <span>View Details</span>
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <Tilt
                      options={{
                        max: 10, // max tilt rotation (in degrees)
                        scale: 1.01, // scale when tilted
                        speed: 900, // speed of tilt animation
                        glare: true, // enable glare effect
                        "max-glare": 0.5,
                      }}
                    >
                      <div className="text-center services-image">
                        <Image
                          src="pention-fund.png"
                          alt="pention-fund"
                          className="w-100"
                        />
                      </div>
                    </Tilt>
                  </div>
                </motion.div>
              )}
            </Tab>
            <Tab eventKey="Systematic" title="Systematic Investment Plan">
              {selectedTab === "Systematic" && (
                <motion.div
                  className="row"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{ duration: 0.5 }}
                >
                  <div className="col-md-6 col-lg-7">
                    <p className="opacity-75">
                      A Recurring Deposit (RD) is a type of term deposit offered
                      by banks and financial institutions that allows customers
                      to deposit a fixed amount of money at regular intervals
                      (monthly, typically) for a predetermined period. It is an
                      ideal savings scheme for individuals with a steady income
                      who want to build a corpus over time while earning
                      interest.
                    </p>
                    <ul className="list-unstyled mb-3 mb-lg-4 services-list">
                      <li>Ideal for long-term wealth creation.</li>
                      <li>Encourages a disciplined approach to saving.</li>
                      <li>Encourages a disciplined approach to saving.</li>
                      <li>
                        Can align with financial goals like education,
                        retirement, or property.
                      </li>
                    </ul>
                    <button className="download-btn bg-secondary-theme">
                      <span>View Details</span>
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <Tilt
                      options={{
                        max: 10, // max tilt rotation (in degrees)
                        scale: 1.01, // scale when tilted
                        speed: 900, // speed of tilt animation
                        glare: true, // enable glare effect
                        "max-glare": 0.5,
                      }}
                    >
                      <div className="text-center services-image">
                        <Image
                          src="systamtaic-plan.png"
                          alt="systamtaic-plan"
                          className="w-100"
                        />
                      </div>
                    </Tilt>
                  </div>
                </motion.div>
              )}
            </Tab>
            <Tab eventKey="Recurring" title="Recurring Deposit Scheme">
              {selectedTab === "Recurring" && (
                <motion.div
                  className="row"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{ duration: 0.5 }}
                >
                  <div className="col-md-6 col-lg-7">
                    <p className="opacity-75">
                      A Recurring Deposit (RD) is a type of term deposit offered
                      by banks and financial institutions that allows customers
                      to deposit a fixed amount of money at regular intervals
                      (monthly, typically) for a predetermined period. It is an
                      ideal savings scheme for individuals with a steady income
                      who want to build a corpus over time while earning
                      interest.
                    </p>
                    <ul className="list-unstyled mb-3 mb-lg-4 services-list">
                      <li>Encourages disciplined savings.</li>
                      <li>Provides guaranteed returns with no market risk.</li>
                      <li>Flexible tenure options.</li>
                    </ul>
                    <button className="download-btn bg-secondary-theme">
                      <span>View Details</span>
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <Tilt
                      options={{
                        max: 10, // max tilt rotation (in degrees)
                        scale: 1.01, // scale when tilted
                        speed: 900, // speed of tilt animation
                        glare: true, // enable glare effect
                        "max-glare": 0.5,
                      }}
                    >
                      <div className="text-center services-image">
                        <Image
                          src="recurring-deposit.png"
                          alt="recurring-deposit"
                          className="w-100"
                        />
                      </div>
                    </Tilt>
                  </div>
                </motion.div>
              )}
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Services;
