import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Download = () => {
  const downloadBg = {
    backgroundImage: "url('download-bg.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    height: "200px",
  };
  return (
    <>
      <section className="download-content mt-4">
        {/* <div className="text-lg-end container">
          <h2 className=" mb-3">
            Experience Seamless Banking: Digital Banking
          </h2>
          <button className="d-flex align-items-center justify-content-center gap-2 download-btn d-lg-inline">
            <span>Download App</span>
            <i className="bx bx-chevrons-right"></i>
          </button>
        </div> */}
        <div className="container">
          <div className="top-header row p-32px rounded-top-3">
            <div className="col-md-2">
              <h4 className="text-white mb-3">Quick Links</h4>
              <div className="branch-locator">
                <DropdownButton
                  id="branch-dropdown"
                  title={
                    <>
                      <i className="bx bxs-bank fs-5"></i>{" "}
                      <span>Locate Us</span>
                    </>
                  }
                >
                  <Dropdown.Item href="/locate-us">Kolhapur</Dropdown.Item>
                  <Dropdown.Item href="/locate-us-branches">
                    Satara
                  </Dropdown.Item>
                  <Dropdown.Item href="/locate-us-atm">Sangli</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <div className="col-md-10 text-center">
              <h4 className="text-white mb-3">
                Download our Mobile Banking App
              </h4>
              <button className="d-flex align-items-center justify-content-center gap-2 download-btn d-lg-inline">
                <span>Download App</span>
                <i className="bx bx-chevrons-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
