import React, { useEffect } from "react";
import { Button, Container, Navbar, Nav } from "react-bootstrap";
import Header from "./component/Header";
import Marquee from "react-fast-marquee";
import Scheme from "./component/Scheme";
import "boxicons";
import "boxicons/css/boxicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "./component/Footer";
import NewsBlog from "./component/NewsBlog";
import Testimonials from "./component/Testimonials";
import "swiper/css";
import "swiper/css/pagination";
import Download from "./component/Download";
import About from "./component/About";
import Services from "./component/Services";
import Home from "./component/Home";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease", // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
      <Header />
      <div>
        <Home />
        <About />
        <Services />
        <NewsBlog />
        <Testimonials />
        <Download />
        <Scheme />
        <Footer />
      </div>
    </>
  );
}

export default App;
