import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const Testimonials = () => {
  const bgImageTestimonials = {
    backgroundImage: "url('testimonials.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  };
  const tsetimonialsData = [
    {
      id: 1,
      image: "testimonials-profile-1.jpg",
      name: "John Beli",
      dataDuration: "1000",
      description:
        "Education is the passport to the future for the tomor row belongs to those who pre pare for it today. Sed ut perspiciatis unde omnis natus errorsit vo luptatem. Education is the passport to the future credit repair.",
    },
    {
      id: 2,
      image: "testimonials-profile-2.jpg",
      name: "Holing Tums",
      dataDuration: "1500",
      description:
        "Education is the passport to the future for the tomor row belongs to those who pre pare for it today. Sed ut perspiciatis unde omnis natus errorsit vo luptatem. Education is the passport to the future credit repair.",
    },
    {
      id: 3,
      image: "testimonials-profile-3.jpg",
      name: "Nikoling Toli",
      dataDuration: "2000",
      description:
        "Sed ut perspiciatis unde omnis natus errorsit vo luptatem. Education is the passport to the future credit repair Education is the passport to the future for the tomor row belongs to those who pre pare for it today.",
    },
  ];
  return (
    <>
      <section
        className="testimonials-content p-32px"
        style={bgImageTestimonials}
      >
        <div className="container">
          <div className="testimonial-div mb-3" data-aos="fade-right">
            <h6 className="txt-theme">Testimonials</h6>
            <h2 className="fw-bold txt-gray-dark">Our Client Reviews</h2>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            autoplay={true}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            breakpoints={{
              768: { slidesPerView: 2 },
              480: { slidesPerView: 1 },
            }}
          >
            {tsetimonialsData.map((testimoials, index) => (
              <SwiperSlide key={testimoials.id}>
                <div
                  className="testimonials-cards"
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration={testimoials.dataDuration}
                >
                  <div className="mb-3 mb-lg-5">
                    <p className="txt-gray-dark fs-5 fw-medium">
                      {testimoials.description}
                    </p>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <div className="testimonials-profile">
                      <img src={testimoials.image} alt={testimoials.name} />
                    </div>
                    <h5 className="txt-gray-dark fw-bold">
                      {testimoials.name}
                    </h5>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
