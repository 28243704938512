import React from "react";

const About = () => {
  const bgAbout = {
    backgroundImage: "url('about.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#000",
  };
  // const bgAboutTheme = {
  //   backgroundImage: "url('')",
  //   backgroundSize: "cover",
  //   backgroundPosition: "center",
  //   backgroundRepeat: "no-repeat",
  //   color: "#000",
  //   backgroundColor: " rgb(238, 238, 238)",
  // };

  return (
    <div className="about-content">
      <section className="" data-aos="fade-up">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="p-32px">
                <div className="mb-3 mb-lg-5" data-aos="fade-right">
                  <h6 className="txt-theme">About us</h6>
                  <h2 className="fw-bold txt-gray-dark">
                    We are here to serve with <br /> 4+ years
                  </h2>
                </div>
                <p className="">
                  Kolhapur's Yashvant Urban Co-operative Patsanstha has been
                  merged in Chhatrapati Sambhaji Maharaj patsanstha and their
                  customers and Levantine had co-operated with us very easily.
                  For this co-operation they have been honored by giving
                  coconut, garlands, and shawl at their residential places.85
                  years old guide Mr. Dada Topkar, customer and their
                  well-wisher Mr. Sanjay Ghatge (Mama), Mr. and Mrs. Jitendra
                  Sakhalakar were honored by organization’s officers Mr. B. G.
                  Patange and Bhanudas Lembhe. They are overwhelmed by the
                  majesty of "Chhatrapati".
                </p>
                <button className="download-btn bg-secondary-theme">
                  <span>Learn More</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <div className="row p-32px shadow rounded-3" style={bgAbout}>
                <div
                  className="col-md-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="">
                    <div class="about-div position-relative">
                      <img src="community.png" alt="Icon" />
                    </div>
                    <h2 className="mb-3 fw-bold fs-1 text-white">21,150</h2>
                    <h5 class="text-white text-uppercase font-weight-600 font-16">
                      Depositor number
                    </h5>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div class="">
                    <div class="about-div position-relative">
                      <img src="deposit-amount.png" alt="Icon" />
                    </div>
                    <h2 className="mb-3 fw-bold fs-1 text-white">
                      1,15,30,555
                    </h2>
                    <h5 class="text-white text-uppercase font-weight-600 font-16">
                      Deposit amount
                    </h5>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div class="">
                    <div class="about-div position-relative">
                      <img src="shield.png" alt="Icon" />
                    </div>
                    <h2 className="mb-3 fw-bold fs-1 text-white">100</h2>
                    <h5 class="text-white text-uppercase font-weight-600 font-16">
                      Safe deposit ratio
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
