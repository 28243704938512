import React from "react";
import "./footer.css";
import { Image } from "react-bootstrap";

const Footer = () => {
  const footerBg = {
    backgroundImage:
      "url('https://arena.km.ua/wp-content/uploads/3538533.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  };
  return (
    <>
      <section className="footer-content" style={footerBg}>
        <footer className="footer-section">
          <div className="container">
            <div className="pt-5 pb-5">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 mb-50">
                  <div className="footer-widget">
                    <div className="mb-2">
                      <a href="index.html">
                        <Image
                          src="/logo-theme.png"
                          alt="logo-theme"
                          height={100}
                        />
                      </a>
                    </div>
                    <div className="footer-text">
                      <p>
                        Lorem ipsum dolor sit amet, consec tetur adipisicing
                        elit, sed do eiusmod tempor incididuntut consec tetur
                        adipisicing elit,Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Useful Links</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">About us</a>
                      </li>
                      <li>
                        <a href="#">Products</a>
                      </li>
                      <li>
                        <a href="#">News & Events</a>
                      </li>
                      <li>
                        <a href="#">Testimonials</a>
                      </li>
                      <li>
                        <a href="#">Download App</a>
                      </li>
                      <li>
                        <a href="#">Footer</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Others Links</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="#">Downloads</a>
                      </li>
                      <li>
                        <a href="#">Branches</a>
                      </li>
                      <li>
                        <a href="#">Career</a>
                      </li>
                      <li>
                        <a href="#">Banking Blogs</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-50">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Contact Us</h3>
                    </div>
                    <div className="footer-text mb-25">
                      <p>
                        Pimpode Budrook, Tal.: Koregaon, Dist.: Satara Phone:
                        251200 Mobile: 9766434563, 9766599601
                      </p>
                    </div>

                    <div className="footer-social-icon mt-3">
                      <a href="#">
                        <i className="bx bxl-facebook facebook-bg text-white fs-4" />
                      </a>
                      <a href="#">
                        <i className="bx bxl-twitter twitter-bg text-white fs-4" />
                      </a>
                      <a href="#">
                        <i className="bx bxl-google google-bg text-white fs-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                  <div className="copyright-text">
                    <p>
                      Copyright © 2024, All Right Reserved{" "}
                      <a href="#" className="">
                        Neosao
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;
