import React from "react";
const NewsBlog = () => {
  const newsData = [
    {
      id: 1,
      image: "news-1.png",
      date: "8 Nov 2024",
      title: "What Does It Mean When You Have No Credit Score?",
      description:
        "Mr. Rambhau Lembhe while felicitating Hon. Mr.Shekhar Charegaonkar for selecting him as a chairman of Maharashtra State Cooperation Council.",
      dataDuration: "1000",
    },
    {
      id: 2,
      image: "news-2.png",
      date: "15 Nov 2024",
      title: "How to Build a Great Financial Portfolio",
      description:
        "Cooperation experts Mr. Shekhar Charegaonkar while receiving Vaibhav Award.",
      dataDuration: "1000",
    },
    {
      id: 3,
      image: "news-3.png",
      date: "22 Nov 2024",
      title: "Top 5 Credit Card Tips Everyone Should Know",
      description:
        "Discover essential tips to make the most of your credit cards...",
      dataDuration: "1500",
    },
    {
      id: 4,
      image: "news-4.png",
      date: "29 Nov 2024",
      title: "Understanding Interest Rates in Simple Terms",
      description:
        "We simplify interest rates and how they impact your finances...",
      dataDuration: "2000",
    },
  ];

  return (
    <section className="p-32px news-content">
      <div className="text-center mb-3 mb-lg-5" data-aos="fade-right">
        <h6 className="txt-theme">News & Events</h6>
        <h2 className="fw-bold txt-gray-dark">
          Read Our Latest News & <br /> Events
        </h2>
      </div>
      <div className="container">
        <div className="row">
          {newsData.map((news, index) => (
            <div className="col-md-6 col-lg-4 col-xl-3" key={index}>
              <div
                className="h-100 bg-white"
                data-aos="fade-up"
                data-aos-duration={news.dataDuration}
              >
                <div className="new-cards h-100">
                  <div className="overflow-hidden position-relative">
                    <img src={news.image} alt={news.title} className="w-100" />
                    <button className="btn-news btn-date">{news.date}</button>
                  </div>
                  <div className="p-3">
                    <p className="fs-6">{news.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 text-center">
          <button className="download-btn bg-secondary-theme">
            <span>View More</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default NewsBlog;
